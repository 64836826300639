<template>
  <b-card title="Schedule">
    <b-tabs>
      <b-tab
        :active="currentRouteName=='schedule-classical-course'"
        active
        @click="$router.push('/schedule-classical-course')"
      >
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Schedule Classic Course</span>
        </template>

      </b-tab>

      <b-tab
        :active="currentRouteName=='schedule-online-course'"
        @click="$router.push('/schedule-online-course')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Schedule Online Course</span>
        </template>

      </b-tab>
      <b-tab
        :active="currentRouteName=='all-Schedule' || currentRouteName=='schedule-update'"
        @click="$router.push('/all-Schedule')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Schedule  Course List</span>
        </template>

      </b-tab>

    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <ScheduleClassicCourse v-if="route.name === 'schedule-classical-course' " />
      <router-view v-else>
        <component :is="Component" />
      </router-view>
    </router-view>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'

import ScheduleClassicCourse from './ScheduleClassicCourse.vue'
import ScheduleOnlineCourse from './ScheduleOnlineCourse.vue'
import ViewSchedule from './ViewSchedule.vue'

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,

    ScheduleClassicCourse,
    ScheduleOnlineCourse,
    ViewSchedule,
  },
  data() {
    return {

    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
}
</script>
<style>
h4.card-title {
    color: #D69900;
}

</style>
